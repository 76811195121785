<template>
  <div>
    <div v-if="authState === 'signedin'" class="main-box">
      <div class="main-text">
        <h2 class="mb-5 text-center">特定商取引法に基づく表示</h2>
        <p class="membership-text">事業者</p>
        <p>一般社団法人日本社会イノベーションセンター</p>
        <p class="membership-text">住所</p>
        <p>〒113-0033 東京都文京区本郷5丁目27-8 赤門樋口ビル2F</p>
        <p class="membership-text">連絡先</p>
        <p>
          Email： team_res_group"at"jsic.or.jp（送信時はatを@に変換）
        </p>
        <p class="membership-text">代表者</p>
        <p>代表理事 堀井秀之</p>
        <p class="membership-text">月額料金</p>
        <p>トップページの解析画面上部に表示した金額を月当たりで積算</p>
        <p class="membership-text">月額料金以外の必要料金</p>
        <p>なし</p>
        <p class="membership-text">支払い方法</p>
        <p>
          クレジットカード(Visa、Mastercard、JCB、アメリカンエキスプレス、ダイナース)
        </p>
        <p class="membership-text">支払い時期</p>
        <p>
          サービスはご登録が完了し次第、すぐに利用可能になります。
        </p>
        <p class="membership-text">サービスの開始時期</p>
        <p>
          Teamvisは月単位のサービスです。
          月額料金は、加入手続きが完了した日に最初の請求が発生し、その後毎月同じ日に請求が行われます。
        </p>
        <p class="membership-text">キャンセル</p>
        <p>
          すでに解析が済んでいるものに関しては返金・キャンセルはできません。
          月額サービス自体のキャンセルは会員画面の[会員登録・変更]から可能です。
        </p>
      </div>
    </div>
    <div v-else class="main-box">
      <div class="button">
        <v-btn to="/signin" color="#ff9900"> ログイン </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["authState", "user_props"],
};
</script>

<style lang="scss" scoped>
.list {
  list-style-type: none;
}
.main-box {
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px;
  background-color: #f5f5f5;
}
.membership-box {
  justify-content: center;
  align-items: center;
  max-width: 720px;
  margin: auto;
  padding: 5px 20px 10px;
}
.membership-text {
  color: grey;
  font-size: 13px;
  margin: 10px auto 2px;
}
.main-text {
  justify-content: center;
  align-items: center;
  max-width: 720px;
  margin: auto;
  padding: 5px 20px 10px;
}
.button {
  width: 160px;
  margin: 10px auto;
}
.text-notice {
  color: grey;
  margin-bottom: 0px;
}
.link_simple {
  text-decoration: none;
}
</style>
